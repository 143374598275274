<template>
  <div>
    <div class="columns is-gapless is-centered is-mobile px-4 my-4">
      <div class="column is-12-mobile is-6-tablet is-6-desktop is-4-widescreen is-4-fullhd">

        <div v-if="page === 1" class="tutorial-container">
          <div>
            <div class="container has-text-centered">
            </div>
            <div class="container has-text-centered pt-6 pb-3 mt-5">
              <b>We care about your privacy</b> — there will be certain features in the game that may require your geo-location.
              Your browser will prompt you to give permission.
            </div>
            <div class="container has-text-centered pb-3">
              <b>We do not store or track your location</b> -- it will be used for on-the-spot for location-relevant tasks or the "scavenge" feature to discover nearby items.
            </div>
            <div class="container has-text-centered pb-6">
              <b>Your location data is then immediately deleted.</b>
            </div>
          </div>
        </div>

        <div v-if="page === 2" class="tutorial-container">
          <div>
            <div class="container has-text-centered">
            </div>
            <div class="container has-text-centered px-6 mt-5">
              The objective of this game is to gain <b>points</b> to advance from one <b>chapter</b> to another.
            </div>
          </div>
        </div>

        <div v-if="page === 3" class="tutorial-container">
          <div>
            <div class="container has-text-centered">
            </div>
            <div class="container has-text-centered px-6 mt-5">
              Each chapter has 1 or more tasks to complete. Each <b>task</b> will give you some points.
            </div>
          </div>
        </div>

        <div v-if="page === 4" class="tutorial-container">
          <div>
            <div class="container has-text-centered">
            </div>
            <div class="container has-text-centered px-6 mt-5">
              Some tasks allow you to <b>skip</b>, although you will typically have points deducted at the end of the game.
            </div>
          </div>
        </div>

        <div v-if="page === 5" class="tutorial-container">
          <div>
            <div class="container has-text-centered">
            </div>
            <div class="container has-text-centered px-6 mt-5">
              Or...buy <b>clues</b> using <b>coins</b>. You get a certain amount of coins at the start, and it cannot be replenished.
            </div>
          </div>
        </div>

        <div v-if="page === 6" class="tutorial-container">
          <div>
            <div class="container has-text-centered">
            </div>
            <div class="container has-text-centered px-6 mt-5">
              For some tasks, you may find an <b>item</b> that will give you more info.
            </div>
          </div>
        </div>

        <div v-if="page === 7" class="tutorial-container">
          <div>
            <div class="container has-text-centered">
            </div>
            <div class="container has-text-centered px-6 mt-5">
              Look out for the <b>timer</b> as the overall game and specific chapters may have time limits.
            </div>
          </div>
        </div>

        <div v-if="page === 8" class="tutorial-container">
          <div>
            <div class="container has-text-centered">
            </div>
            <div class="container has-text-centered px-6 mt-5">
              Once you have the points required for the chapter, you automatically go to the next.
            </div>

            <div class="container has-text-centered has-text-weight-semibold px-6 mt-5">
              Have fun!
            </div>
          </div>
        </div>

        <div class="button-container">
          <div class="container has-text-centered">
            <nav class="level is-mobile px-6">
              <div v-for="index in 8" :key="index">
                <i v-if="index === page" class="fas fa-circle has-text-brand-secondary-color"></i>
                <i @click="changeToPage(index)" v-if="index !== page" class="far fa-circle has-text-grey-light pointer"></i>
              </div>
            </nav>
            <div
            @click="changePage(1)"
            v-if="page < 8"
            class="button is-primary-colors has-text-weight-semibold px-6"
            >
              Next
            </div>
            <div
            @click="endTutorial()"
            v-if="page === 8"
            class="button is-primary-colors has-text-weight-semibold px-6"
            >
              Start
            </div>
          </div>
        </div>
       
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tutorial',
  data() {
    return {
      page: 1
    }
  },
  computed: {
    completedTutorial(){
      return this.$store.state.completedTutorial
    }
  },
  watch: {
    completedTutorial(){
      if(this.completedTutorial){
        this.$router.push({ name: 'Stage' })
      }
    }
  },
  methods: {
    changePage(noOfPages){
      this.page = this.page + noOfPages
    },
    changeToPage(page){
      this.page = page
    },
    endTutorial(){
      this.$store.commit('updateCompletedTutorial', true)
    }
  },
  mounted(){
    if (this.$store.state.completedTutorial) {
      this.$router.push({ name: 'Stage' })
    }
  }
}
</script>

<style>
.tutorial-container {
  height: 70vh;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.button-container {
  height: 30vh;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}
</style>
